<template>
  <b-card>
    <cabecalho title="CONTABIL_FECHAMENTO.PAGE_TITLE" linkRetornar="contabil" />
    <filtro-contabil @obter-resultado="obterResultado" />
    <tabela-contabil :items="items" />
  </b-card>
</template>
<script>
// Utils & Aux:
import breadcrumb from '@/common/utils/breadcrumb';

//Components:
import { Cabecalho } from '@/components/headers/index';
import FiltroContabil from './components/Filtro';
import TabelaContabil from './components/Tabela';

export default {
  components: {
    Cabecalho,
    FiltroContabil,
    TabelaContabil,
  },
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: this.$t('CONTABIL_FECHAMENTO.CONTABIL') },
      { titulo: this.$t('CONTABIL_FECHAMENTO.PAGE_TITLE') },
    ]);
  },
  data() {
    return {
      items: [{}],
    };
  },
  methods: {
    obterResultado() {},
  },
};
</script>
