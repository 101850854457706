<template>
  <filtro
    :filtro="true"
    btnFiltrarTexto="CONTABIL_FECHAMENTO.LISTAR_PERIODOS_CONTABEIS"
    @filtrar="filtrar"
    @limpar-filtro="limparFiltro"
  >
    <div class="row">
      <div class="col-12 col-md-4">
        <input-select-search
          ref="curso"
          v-model="filtro.selecionado"
          :label="$t(`CONTABIL_FECHAMENTO.ANO_CONTABIL`)"
          :options="filtro.anos"
          required
        />
      </div>
    </div>
  </filtro>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';

// Components:
import Filtro from '@/components/filtro/Filtro';
import { InputSelectSearch } from '@/components/inputs';

export default {
  components: {
    Filtro,
    InputSelectSearch,
  },
  mounted() {
    this.getAnos();
  },
  data() {
    return {
      filtro: {
        selecionado: '',
        resultadoBusca: [],
        anos: [],
      },
    };
  },
  methods: {
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    getAnos() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar(this.paginacao)
        .then(({ data }) => {
          data.forEach((element) => {
            element.text = element.ano;
            element.value = element.id;
          });
          this.filtro.anos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES DO FILTRO:
    filtrar() {
      this.emitirDados();
    },
    emitirDados() {
      this.$emit('obter-resultado', this.resultadoBusca);
    },
    limparFiltro() {
      this.selecionado = '';
    },
  },
};
</script>
