<template>
  <div :class="marginBottom">
    <!-- FILTRO SIMPLES -->
    <slot v-if="filtroConteudo" />

    <!-- FILTRO DE BUSCA AVANÇADA -->
    <slot
      name="filtroAvancado"
      v-if="filtroConteudo && filtroConteudoBuscaAvancada"
    />

    <!-- BOTÕES -->
    <div class="row mt-3" v-if="!ocultarBotoes">
      <!-- BUSCAR ITENS -->
      <div class="col-12 col-md-auto mb-2 mb-md-0 order-1" v-if="!apenasLimpar">
        <b-button variant="primary" class="w-100" @click="filtrar">
          {{ $t(btnFiltrarTexto) }}
        </b-button>
      </div>

      <!-- OCULTAR FILTRO -->
      <div
        class="col-12 col-md-auto mb-2 mb-md-0 order-2"
        v-if="!apenasLimpar && btnOcultarMostrar"
      >
        <b-button
          :variant="btnOcultarFiltroCor"
          class="w-100"
          v-b-tooltip.hover
          :title="btnOcultarFiltroTexto"
          @click="showHidefiltro"
        >
          <font-awesome-icon :icon="btnOcultarFiltroIcon" />
        </b-button>
      </div>

      <!-- LIMPAR FILTRO -->
      <div class="col-12 col-md-auto mb-2 mb-md-0 order-3">
        <b-button
          :variant="btns.limparFiltro.cor"
          class="w-100"
          v-b-tooltip.hover
          :title="btns.limparFiltro.texto"
          @click="limparFiltro"
        >
          {{ $t('GERAL.LIMPAR') }}
        </b-button>
      </div>

      <!-- EXIBIR BUSCA AVANÇADA -->
      <div class="col-12 col-md-auto mb-2 mb-md-0 order-4" v-if="buscaAvancada">
        <b-button
          variant="outline-success"
          class="w-100"
          v-b-tooltip.hover
          :title="$t('GERAL.BUSCA_AVANCADA')"
          @click="showHideFiltroAvancado"
        >
          <font-awesome-icon icon="fa-solid fa-filter" />
        </b-button>
      </div>

      <slot name="outrosBotoes" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // props para filtro simples e avançado:
    filtro: { type: Boolean, default: false },
    filtroAvancado: { type: Boolean, default: false },

    // prop para texto estilizado no btn de filtrar:
    btnFiltrarTexto: { type: String, default: 'Filtrar' },
    marginBottom: { type: String, default: 'mb-5' },

    // props de botões:
    buscaAvancada: { type: Boolean, default: false },
    ocultarBotoes: { type: Boolean, default: false },
    btnOcultarMostrar: { type: Boolean, default: true },
    apenasLimpar: { type: Boolean, default: false },
  },
  data() {
    return {
      exibir: this.filtro,
      exibirBuscaAvancada: this.filtroAvancado,
      btns: {
        ocultarFiltro: {
          icon: 'fa-solid fa-eye',
          cor: 'outline-warning',
          texto: this.$t('GERAL.OCULTAR_FILTRO'),
        },
        limparFiltro: {
          icon: 'fa-solid fa-arrows-rotate',
          cor: 'outline-info',
          texto: this.$t('GERAL.LIMPAR_FILTRO'),
        },
      },
    };
  },
  computed: {
    // EXIBIÇÃO FILTROS:
    filtroConteudo() {
      return this.exibir;
    },
    filtroConteudoBuscaAvancada() {
      return this.exibirBuscaAvancada;
    },
    // CONFIGURAÇÃO BOTÕES
    // ocultar:
    btnOcultarFiltroIcon() {
      return this.exibir ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash';
    },
    btnOcultarFiltroCor() {
      return this.exibir ? 'outline-danger' : 'danger';
    },
    btnOcultarFiltroTexto() {
      return this.exibir
        ? this.$t('GERAL.OCULTAR_FILTRO')
        : this.$t('GERAL.EXIBIR_FILTRO');
    },
  },
  methods: {
    filtrar() {
      this.$emit('filtrar');
    },
    limparFiltro() {
      this.$emit('limpar-filtro', this.filtro); // Emita o filtro para o componente pai
    },
    showHidefiltro() {
      this.exibir == true ? (this.exibir = false) : (this.exibir = true);
    },
    showHideFiltroAvancado() {
      this.exibirBuscaAvancada == true
        ? (this.exibirBuscaAvancada = false)
        : (this.exibirBuscaAvancada = true);
    },
  },
};
</script>