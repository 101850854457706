<template>
  <b-table
    :fields="fields"
    :items="items"
    show-empty
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    class="my-5"
    hover
  >
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item @click="processarFechamento(item.item)">
          Processar fechamento de período
        </b-dropdown-item>
        <b-dropdown-item @click="processarReabertura(item.item)">
          Processar reabertura de período
        </b-dropdown-item>
        <b-dropdown-item @click="exportarLancamentos(item.item)">
          Exportar lançamentos contábeis
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'mesReferencia',
          label: this.$t('CONTABIL_FECHAMENTO.MES_REFERENCIA'),
          sortable: true,
        },
        {
          key: 'dataInicio',
          label: this.$t('CONTABIL_FECHAMENTO.DATA_INICIAL'),
          sortable: true,
        },
        {
          key: 'dataFim',
          label: this.$t('CONTABIL_FECHAMENTO.DATA_FIM'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$t('CONTABIL_FECHAMENTO.STATUS'),
          sortable: true,
        },
        {
          key: 'acoes',
          label: this.$t('CONTABIL_FECHAMENTO.ACOES'),
        },
      ],
    };
  },
  methods: {
    processarFechamento(item) {
      this.$emit('processar-fechamento', item);
    },
    processarReabertura(item) {
      this.$emit('processar-reabertura', item);
    },
    exportarLancamentos(item) {
      this.$emit('exportar-lancamentos', item);
    },
  },
};
</script>
